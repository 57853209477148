import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import NewsletterSignup from "../components/newsletterSignup";
import CallToActionGeneric from "../components/callToActionGeneric";

class PrivacyPolicy extends React.Component {
  render() {

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Privacy Policy - Baton Rouge | Lewis Mohr"
          meta={[
            {
              name: "description",
              content:
                "This Privacy Notice is meant to help you understand how Lewis Mohr Insurance Agency, in Baton Rouge, Louisiana, collects, uses, stores, shares and protects the Personal Information you provide, or that we otherwise collect."
            }
          ]}
        />
        <div className="site-body page-content">
          <br />
          <br />
          <h1>Privacy Policy</h1>
          <p>Last Updated: Aug, 2016</p>
        <p>
          This Privacy Notice (as amended from time to time, the “Notice”) is meant to help you understand how Mohr Agency collects, uses, stores, shares and protects the Personal Information (as defined below) you provide, or that we otherwise collect, in connection
          with your use of and access to the https://www.mohragency.com website, and the products, features and/or applications (including, without limitation, offered from time to time in connection therewith (collectively, the “Services”), and to describe
          your rights with respect to such Personal Information. It also describes what data we collect, the choices available to you regarding our use of, your access to, and how to update and correct such data and Personal Information. However, this
          Notice does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage. By visiting, accessing or using the Website or the Services in any manner, you acknowledge and agree to the
          practices and policies outlined in this Notice, and you hereby consent to our collection, use and sharing of your data and Personal Information as described below. We expressly reserves the right to amend, modify or change this Notice at any
          time, and without advance notice to you, in accordance with the terms set forth below. Please read this Notice carefully.
        </p>
        <h3>Application and Scope</h3>
        <p>
          This Notice applies to the Website and the use thereof and access thereto by certain visitors of the Website, including without limitation (a) customers that have policies that we administer (“Policy Agreements”) (“Clients”), and (b) other users of the
          Website who are not Clients. (“Visitors”, and together with Clients, “Users”, “you” or “your”). In the event of a conflict between a Service Agreement and this Notice, the terms of the Service Agreement shall control the rights and obligations
          of the applicable Clients. As applicable, Clients own the Personal Information and other data they provide to us or we receive and/or collect on their behalf.
        </p>
        <h3>Information Collected from You and for Our Clients</h3>
        <p>
          We collect Personal Information both directly from Users and our Clients (current and prospective) If you are a Visitor to our Website and would no longer like to be contacted by us, contact us by emailing our customer support at <a href="mailto:info@mohragency.com">info@mohragency.com</a>
          <br />
          <br /> We may collect or receive detailed and sensitive Personal Information and data from Clients in connection with their use of and access to the Website for purposes of servicing Policy Agreements. We also collect certain Personal Information
          and data from Visitors in connection with their use of and access to the Website. Examples of the types of information and data that we may collect include (collectively, the “Personal Information”):
          <br />
          <ul>
            <li>Contact information, including name, email, telephone, and address;</li>
            <li>Log-in information, including details of how and when you used our Website and Services, what pages you visited, internet protocol address, device and browser used to access the Websites and Services, and Cookies (as defined below) that may
              uniquely identify your browser, device, or account;</li>
            <li>Client Personal Information, including name, address, social security number, date of birth, and other sensitive financial and healthcare related data, and beneficiary information, including, including name, address, social security number,
              and date of birth all collected in conjunction with Services; and if your Personal Information changes, you may correct, update or request to have it deleted by emailing us at <a href="mailto:info@mohragency.com">info@mohragency.com</a>              or at the contact address listed at the end of this Notice. If requested to remove data, we will respond to the request in accordance with the provisions of the applicable Policy Agreement within a reasonable time.</li>
          </ul>
        </p>
        <h3>How We Use Personal Information and Data We Collect</h3>
        <p>
          In general, we use the Personal Information and other data we collect to provide the Services to our Users, to operate the Website, to comply with applicable law, and to enforce the Websites’ terms and conditions of use (the “Conditions of Use”), this
          Notice and our Policy Agreements. For example, we may use collected Personal Information and other data to:
          <br />
          <ul>
            <li>Operate the Website, provide the Services and offer customer support;</li>
            <li>Facilitate User interactions, fulfill any requests for Services, and send notices about your use of the Services (such as changes to your account);</li>
            <li>Resolve disputes, collect fees, and troubleshoot problems;</li>
            <li>Comply with applicable laws and regulations or otherwise seek to prevent potentially prohibited or illegal activities;</li>
            <li>Enforce a Service Agreement;</li>
            <li>Personalize, customize, measure, and improve the Services, and the content, layout, and operation of the Website and related Services and applications;</li>
            <li>Allow Users to register with us and to customize their account, profile, other registration information, and to customize, access, upload and/or use any content or other functionality that may be available through the Services and Website;</li>
            <li>Contact Users for any purpose we deem appropriate;</li>
            <li>Analyze how Users utilize the Website and the Services;</li>
            <li>Compare Personal Information for accuracy and verify it with third parties;</li>
            <li>Share certain Personal Information with third-party service providers to facilitate, provide and enhance the functionality of the Services and the Website (including, but not limited to Insurers, and Policy Agreement Underwriters); and</li>
            <li>Facilitate such uses otherwise set forth in this Notice, the Conditions of Use, and any Service Agreement.</li>
          </ul>
          Users may also authorize us to use, copy, and transmit Personal Information and other data for all purposes relating to any Services. For example, upon a Client’s request, we may transmit Personal Information to federal, state and local governments, financial
          institutions, insurance carriers, and any other recipient identified by such User.
        </p>
        <h3>Information we share</h3>
        <p>
          We may share your Personal Information with additional third parties only in the ways that are described in this Notice.<br />
          <br />We may share a User’s Personal Information with certain third-party companies, service providers and their employees and representatives that help us provide our Services to you and to our Clients (collectively, the “Third Parties”), such
          as facilitating coverage questions and policy elections and otherwise providing certain services and products offered by us (including as may be necessary to fulfill our obligations under a Service Agreement). These Third Parties are authorized
          to use your Personal Information only as necessary to provide to us, and to assist us in providing to you, the foregoing services. Other permitted transfers of your Personal Information to third parties may be specified in the applicable Service
          Agreements with our Clients.<br />
          <br />We may also partner with other companies to jointly offer products or services (the “Joint Promotion Partners”, such as insurance underwriters). If you visit or otherwise attend an event or conference offered by one or more of our Clients
          or Joint Promotion Partners, or you otherwise express interest in such a jointly-offered product or service, we may share your Personal Information with our Joint Promotion Partner(s) or use your Personal Information to contact you concerning
          our Services. If you do not wish for your Personal Information to be shared in this manner, you may opt out of attending the event or conference, or otherwise not purchase or express interest in our Services or a jointly-offered product or service.<br />
          <br />We may also share your Personal Information with:<br />
          <ul>
            <li>Service providers who help with our business operations, such as fraud prevention, bill collection, marketing and technology services; provided, that these service providers may only use your Personal Information in connection with the services
              they provide for us, and not for their own benefit;</li>
            <li>Financial and other institutions that provide the billing, payment processing, funds transfer and services necessary to facilitate the Services, and we and these institutions may share your Personal Information for certain purposes, such as
              to inform you that a payment has been sent or when you make a payment;</li>
            <li>Companies with which we m ay be considering merger, acquisition, sale of our equity or all or a portion of its assets, or any other similar business combination transaction relevant to such information; provided, that (a) we will provide such
              information pursuant to a non-disclosure or confidentiality agreement and (b) should such a merger, acquisition, sale or similar transaction occur, the (i) acquiring or combining entity shall continue to follow this Notice with respect to
              your Personal Information until modified in writing by the acquiring or combining entity or (ii) you are notified that your Personal Information will no longer be subject to this Notice;</li>
            <li>Law enforcement, government officials or other third parties pursuant to a subpoena, court order or other legal process or requirement applicable to us or any of our Insurance underwriters, as more fully described in this Notice; and/or</li>
            <li>When we need to do so to comply with any applicable law, rule, statute, regulation, convention or credit or debit rules, or when we believe, in our sole discretion, that the disclosure of Personal Information is necessary to prevent physical
              harm or financial loss, to report suspected illegal activity, or to investigate violations of this Notice, the Conditions of Use or any Service Agreements.</li>
          </ul>
          <br />We may also disclose your Personal Information to any other third party, other than as described in this Notice, with your prior consent.<br />
          <br /><b>California Privacy Rights:</b> Under California Civil Code Sections 1798.83-.84, California residents may ask us for a notice (a) identifying the categories of Personal Information which we share with our affiliates and/or third parties
          for marketing purposes, and (b) providing contact information for such affiliates and/or third parties. If you are a California resident and would like a copy of this notice, please contact us at <a href="mailto:info@mohragency.com">info@mohragency.com</a>.
        </p>
        <h3>Retention</h3>
        <p>
          Storage and retention of a Client’s Personal Information is also governed by our applicable Service Agreements with such Clients. In general, we will retain such Personal Information and other data we collect, obtain, hold and/or process on behalf of
          our Clients for as long as needed to provide the Services to such Users, and we retain and use this Personal Information and other data as necessary to comply with our legal obligations, resolve disputes, and enforce this Notice, the Conditions
          of Use and our Service Agreements. Upon the expiration or earlier termination of such Service Agreements, we may destroy such Personal Information and other data at any time.
        </p>
        <h3>Compliance</h3>
        <p>
          We uses commercially reasonable efforts to comply with all applicable federal, state, and local laws and regulations. As such, we may disclose your Personal Information as required by law, such as to comply with subpoenas, bankruptcy proceedings, or similar
          legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request. For the avoidance of doubt, we may report
          any information or data we, including, without limitation, your Personal Information, to one or more governmental authorities to the extent we, in our sole discretion, deems such reporting necessary.
        </p>
        <h3>Electronic Mail</h3>
        <p>
          We may contact you by email or other means. We will never ask you for your account password. We may contact you for solicitation and advertising from time to time, and if you wish to subscribe to any of our newsletters, we will use your name and email
          address to send the newsletter to you. We will provide you with a way to unsubscribe to the newsletter by following the unsubscribe instructions included in those emails.<br />
          <br />We may also send you service-related email announcements from time to time when it is necessary to do so. For instance, if our Services or Website are temporarily suspended for maintenance, we might send you an email. You do not have an
          option to opt out of these service-related emails, which are not promotional in nature.
        </p>
        <h3>Cookies and Other Tracking Technologies</h3>
        <p>
          Like most web sites, when you use or access the Website or the Services, we gathers certain information automatically and stores it in log files. Examples of information included in such log files are: internet protocol (IP) addresses, browser type, referring/exit
          pages, operating system, date/time stamp, username and pages visited. We may link and combine this automatically collected log information with the Personal Information and other data we collect about you. We do this to improve our Services,
          marketing analytics and functionality of the Website.<br />
          <br />When you use or access the Website or the Services, we (and our advertisers, business partners, and other companies that help us render the Services and operate and maintain the Website, including the Third Parties and Joint Promotion Partners)
          may, and may permit its partners to, use these technologies and to place small data files on, or otherwise create and store a unique anonymous identifier that may be code, first-party or other cookies, beacons, pixel tags, scripts, flash cookies
          or other local storage provided by your browser or associated applications (collectively, “Cookies”). We also use third party services (such as Google Analytics) to help analyze how Users use the Website and Services, and these third-party services
          may place Cookies on your device to collect certain internet log information and visitor behavior information in anonymous form.<br />
          <br />We may use these technologies, such as Cookies, to analyze trends, administer and track use of the Website, and to gather demographic information about our user-base as a whole. Moreover, we may use these technologies to: (a) recognize
          you; (b) verify your identity; (c) customize the Services and advertising; (d) help ensure that your account security is not compromised; (e) collect traffic data, such as whether you are a new or returning visitor, the timestamp of your current
          visit to the Website, and the referrer site or campaign that directed you to the Services (e.g., search engine, keywords, banner or email); and (f) mitigate risk and prevent fraud through the Services. We may receive reports based on the use
          of these technologies by third party companies on an individual as well as aggregated basis.<br />
          <br />New may use both session and persistent Cookies. Session Cookies expire and no longer have any effect when you log out of your account or close your browser; persistent Cookies remain on your device until you erase them or they expire.
          If you click on a link to a third-party website, such third party may also transmit Cookies to you. This Notice does not cover the use of Cookies by any third parties.<br />
          <br />We may use Cookies to remember a User’s settings and for purposes of authenticating a User. Although you are free to decline our Cookies if your browser permits, doing so may interfere with your use of the Services. For example, you may
          not be able to access certain aspects, or all, of the functionality of the Services if you decline, reject or delete Cookies, and may not be able to complete the log-in process at all, because we will be unable to authenticate you as an authorized
          User. Refer to the help section of your browser, browser add-on or extension, or installed applications for instructions on blocking, deleting or disabling Cookies.<br />
          <br />We may use Local Storage, such as HTML5, to store content information and preferences. Third parties with whom we may partner to provide certain features on our Websites or to display advertising based upon your web browsing activity also
          use Flash Cookies and HTML5 to collect and store information. Various browsers may offer their own management tools for removing HTML5.<br />
          <br />We may partner with one or more third parties to either display advertising on our Websites or to manage our advertising on other sites. Our third-party partners may use technologies such as Cookies to gather information about your activities
          on the Websites and such other sites in order to provide you advertising based upon your browsing activities and interests. If you wish to not have this information used for the purpose of serving you interest-based ads, you may opt-out by clicking
          here. Please note this does not opt you out of being served ads – you will continue to receive generic ads.<br />
          <br />We may use mobile analytics software to allow us to better understand the functionality of our Service Platform on your smartphone or other mobile device. This software may record information such as how often you use the Service Platform,
          the events that occur within the Service Platform, aggregated usage, performance data, and where the Service Platform was downloaded from. We may link the information we store within the analytics software to any Personal Information you submit
          within the Service Platform.<br />
        </p>
        <h3>Links to 3rd party Sites</h3>
        <p>
          The Website and Services include links to other sites whose privacy practices may differ from ours. If you submit Personal Information or other data to any of those sites, such information and data is governed by the privacy policies and practices of
          these third party sites. We encourage you to carefully read the privacy notice/policy of any site you visit.<br />
        </p>
        <h3>Referrals</h3>
        <p>
          If you choose to refer a potential customer or Client (a “Referral”), we will ask you for that Referral’s name, email address, company and contact information. We may contact the Referral by phone or email. We may store this information for the purpose
          of contacting the Referral by phone or email and tracking the success of our referral program. The Referral may contact us at <a href="mailto:info@mohragency.com">info@mohragency.com</a> to request that we remove this information from
          our database.<br />
        </p>
        <h3>Social Media Widgets</h3>
        <p>
          The Website and Services may include certain social media features, such as the Facebook and Twitter buttons or interactive mini-programs that run on our Website. These features may collect your Internet protocol address, which page you are accessing
          and using the Website, and may employ the use of Cookies to enable the feature to function properly. Social media features or widgets are either hosted by a third party or hosted directly on our Website. Your interactions with these features
          are governed by the privacy policy/notice of the company providing it.<br />
        </p>
        <h3>Changes</h3>
        <p>
          WE EXPRESSLY RESERVE THE RIGHT TO AMEND, MODIFY OR CHANGE THIS NOTICE AT ANY TIME, AND WITHOUT ADVANCE NOTICE TO YOU. If we make material changes we will notify Users by email or by means of a notice on one or more of the Website prior to or upon the
          amendment, modification or change becoming effective. We encourage you to periodically review this Notice for the latest information on our privacy policies and practices. If we consummates a merger, acquisition, or sale of all or substantially
          all of our assets, you will be notified via email and/or a prominent notice on one or more of the Website of any change in ownership or uses of your Personal Information, as well as any choices you may have regarding your Personal Information.
          Notwithstanding anything to the contrary in this Notice, we may share your Personal Information with one or more acquiring parties for the same purposes for which we may use your Personal Information as described in this Notice.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns regarding this Notice, the practices of the Website, your use of, access to or dealings with the Website, or otherwise regarding our policies and practices with respect to your Personal Information, please send us
          a detailed message using the contact information below:<br />
          <br /><a href="mailto:info@mohragency.com">info@mohragency.com</a> or <a href="tel:1-225-293-1086">1-225-293-1086</a>
        </p>
        </div>
        <CallToActionGeneric bg={this.props.data.callToActionBg} />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default PrivacyPolicy;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-28.jpg" }) {
      ...mainBg
    }
  }
`;
